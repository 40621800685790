import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { Apple, Android } from "@icons-pack/react-simple-icons"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="The Big Button" />
    <Logo>
      the<br></br>
      big<br></br>
      button
      <Icon>
        <DotContainer>
          <Dot></Dot>
          <Dot style={{ right: 0 }}></Dot>
          <Dot style={{ bottom: 0 }}></Dot>
          <Dot style={{ bottom: 0, right: 0 }}></Dot>
        </DotContainer>
      </Icon>
    </Logo>
    <Subtitle>Try to break your high scores!</Subtitle>
    <ButtonLink>
      <OutboundLink href="https://apps.apple.com/us/app/the-big-button/id1495786435">
        <Apple name="Apple" color="white" size={24} />
        <TextLink> Download on App Store</TextLink>
      </OutboundLink>
    </ButtonLink>
    <ButtonLink>
      <OutboundLink href="https://play.google.com/store/apps/details?id=com.inkOfPixel.theBigButton">
        <Android name="Android" color="white" size={24} />
        <TextLink> Download on Google Play</TextLink>
      </OutboundLink>
    </ButtonLink>
  </Layout>
)

export default IndexPage

const Logo = styled.h1`
  font-size: 120px;
  line-height: 0.9em;
  font-weight: 600;
  position: relative;
  margin-bottom: 0;
  @media (max-width: 500px) {
    font-size: 80px;
  }
`

const Subtitle = styled.p`
  padding-bottom: 100px;
  @media (max-width: 500px) {
    padding-bottom: 60px;
  }
`

const Icon = styled.div`
  height: 180px;
  width: 180px;
  border-radius: 45px;
  background-color: ${({ theme }) => theme.colors.black};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 40px;
  left: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    height: 120px;
    width: 120px;
    border-radius: 30px;
    top: 20px;
    left: 140px;
  }
`

const DotContainer = styled.div`
  height: 60px;
  width: 60px;
  position: relative;
  @media (max-width: 500px) {
    height: 40px;
    width: 40px;
  }
`

const Dot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  @media (max-width: 500px) {
    height: 10px;
    width: 10px;
  }
`

const ButtonLink = styled.div`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 300px;
    text-decoration: none;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.black};
    box-shadow: ${({ theme }) => theme.styles.boxShadow};
    transition: ${({ theme }) => theme.styles.transition};
    border-radius: 16px;
    margin-bottom: 20px;
    &:hover {
      box-shadow: ${({ theme }) => theme.styles.boxShadowHover};
    }
    @media (max-width: 500px) {
      width: 260px;
    }
  }
`
const TextLink = styled.span`
  padding-left: 10px;
`
